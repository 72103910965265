import React, { useState, useRef, useEffect, useCallback } from 'react';
import Banner from './Banner';
import UserInfoForm from './UserInfoForm';
import InventoryList from './InventoryList';
import './CombinedForm.css';

const CombinedForm = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [inventoryData, setInventoryData] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState('');
  const [alreadySubmitted, setAlreadySubmitted] = useState(false);

  const userInfoFormRef = useRef(); // Reference to the UserInfoForm component

  const receiveUserInfo = (data) => {
    setUserInfo(data);
  };

  const receiveInventoryData = (data) => {
    setInventoryData(data);
  };

  // Define submitForm using useCallback to ensure it's memorized
  const submitForm = useCallback(async () => {
    setSubmissionStatus('Submitting...');

    try {
      const payload = {
        userInfo: userInfo,
        inventoryList: inventoryData,
      };

      const response = await fetch('https://2mrfk6r5jk7eqs5ixgcillv7hy0hxutk.lambda-url.us-east-2.on.aws/', {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(payload),
        headers: { Origin: 'http://localhost:3000/' },
      });

      if (response.ok) {
        const result = await response.json();
        setSubmissionStatus('Data processed and uploaded successfully!');
        setAlreadySubmitted(true);
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error during data submission:', error);
      setSubmissionStatus('Failed to process data. ' + error.message);
      alert('Failed to process data. Please check the console for more details.');
    }
  }, [userInfo, inventoryData]); // Dependencies for the useCallback

  useEffect(() => {
    // Whenever userInfo is updated and not null, try to submit the form.
    if (userInfo && inventoryData.length && isSubmitted) {
      submitForm();
    }
  }, [userInfo, inventoryData, isSubmitted, submitForm]); // Re-run the effect if these values change

  const handleSubmit = () => {
    setIsSubmitted(true); // Indicate that submission has been attempted
    if (userInfoFormRef.current) {
      userInfoFormRef.current.submit(); // Trigger the UserInfoForm submission
    }
    // The useEffect hook takes over from here...
  };

  if (alreadySubmitted) {
    return (
      <div className="CombinedForm">
        <h2 className="form-header">Finished</h2>
        <p>Thank you for your submission, we look forward to helping you move!</p>
      </div>
    );
  }
  return (
    <>
      {' '}
      <Banner />
      <div className="CombinedForm">
        <h2 className="form-header">User Information</h2>
        <UserInfoForm ref={userInfoFormRef} sendUserInfo={receiveUserInfo} />
        <InventoryList sendInventoryData={receiveInventoryData} />
        <button onClick={handleSubmit} disabled={isSubmitted} className="CombinedForm-submitButton">
          Submit All Data
        </button>
        {submissionStatus && <p>{submissionStatus}</p>}
      </div>
    </>
  );
};

export default CombinedForm;
