import React from 'react';
import './Banner.css'; // Ensure your CSS file is updated accordingly

const Banner = () => {
  return (
    <div className="banner">
      <h1>We Sell Moves</h1>
      <h2>Inventory List</h2>
      <p>Please fill out the information below so we can generate a quote for your move</p>
    </div>
  );
};

export default Banner;
