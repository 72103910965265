import PublicGoogleSheetsParser from 'public-google-sheets-parser';

const googleSheetData = async (sheetId) => {
  const parser = new PublicGoogleSheetsParser();
  const items = await parser.parse(sheetId);
  console.log('Items:', items); // Logging for debugging
  let returnData = {};
  items.forEach((item) => {
    returnData[item['Item']] = item['CUBIC FEET'];
  });

  return returnData;
};

export default googleSheetData;
