import React, { useState, useEffect, useRef } from 'react';
import Downshift from 'downshift';
import DOMPurify from 'dompurify';
import './InventoryList.css';

import googleSheetParser from './itemsData';
import { set } from 'react-hook-form';

const InventoryList = ({ sendInventoryData }) => {
  const emptyItem = { name: '', quantity: 1, cubicFeet: 0, totalCubicFeet: 0, inputValue: '' };
  const initialItems = Array(5)
    .fill()
    .map(() => ({ ...emptyItem }));
  const [items, setItems] = useState(initialItems);
  const [totalCubicFeet, setTotalCubicFeet] = useState(0);
  const [itemsData, setItemsData] = useState([]); // Initialize with the data from the google sheet
  const previousFormattedItems = useRef(); // To store the previous state of formattedItems
  const sanitizeInput = (value) => {
    return DOMPurify.sanitize(value); // Santize input values before setting the state
  };

  const calculateTotalCubicFeet = (items) => {
    return items.reduce((total, item) => total + item.quantity * item.cubicFeet, 0);
  };

  useEffect(() => {
    //initialize the items
    const fetchData = async () => {
      const items = await googleSheetParser('1KkUu0MTetSPVVPAPexQgh_ZLN422397FUaTN07MZbPw');
      console.log('Items:', items); // Logging for debugging
      setItemsData(items);
    };
    fetchData();
  }, []);
  useEffect(() => {
    const total = calculateTotalCubicFeet(items);
    setTotalCubicFeet(total);

    const formattedItems = items.map((item) => ({
      itemName: item.name,
      quantity: item.quantity,
      cubicFeet: itemsData[item.name] || '0', // Fallback to '0' if item not found
      totalCubicFeet: ((item.quantity || 0) * (itemsData[item.name] || 0)).toString(),
    }));

    // Check if formattedItems have actually changed before sending them
    if (JSON.stringify(formattedItems) !== JSON.stringify(previousFormattedItems.current)) {
      console.log('Sending inventory data:', formattedItems); // Logging for debugging
      sendInventoryData(formattedItems); // Sending the data to parent component
      previousFormattedItems.current = formattedItems; // Update the ref with the new value
    }
  }, [items, sendInventoryData]); // Only re-run the effect if 'items' or 'sendInventoryData' changes
  const getFilteredItems = (query) => {
    return Object.keys(itemsData)
      .filter((name) => name.toLowerCase().includes(query.toLowerCase()))
      .slice(0, 10) // Limit the number of results
      .map((name) => ({ name, cubicFeet: itemsData[name] }));
  };

  const handleItemChange = (selectedItem, index) => {
    if (!selectedItem) return; // Exit if no item is selected
    const sanitizedItemName = sanitizeInput(selectedItem.name); // Sanitize the item name
    const updatedItems = items.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          name: sanitizedItemName,
          cubicFeet: itemsData[sanitizedItemName],
          totalCubicFeet: item.quantity * itemsData[sanitizedItemName],
        };
      }
      return item;
    });
    setItems(updatedItems);
  };

  const handleQuantityChange = (quantity, index) => {
    // Parse the input to an integer
    let sanitizedQuantity = parseInt(sanitizeInput(quantity));

    // Check if the parsed quantity is a number and not negative
    if (!isNaN(sanitizedQuantity) && sanitizedQuantity >= 0) {
      const updatedItems = items.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            quantity: sanitizedQuantity,
            totalCubicFeet: sanitizedQuantity * item.cubicFeet,
          };
        }
        return item;
      });
      setItems(updatedItems);
    } else {
      // If the input is not a valid positive number, reset the quantity to 0 or maintain the old value
      const updatedItems = items.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            quantity: 1, // or you can use item.quantity to keep the previous value
            totalCubicFeet: 0 * item.cubicFeet, // or use item.quantity * item.cubicFeet to keep the previous total
          };
        }
        return item;
      });
      setItems(updatedItems);
    }
  };

  return (
    <div className="inventory-list">
      <div className="table-header">
        <div>Item Name</div>
        <div>Quantity of Item</div>
        <div>Cubic Feet Per Item</div>
        <div>Total Cubic Feet</div>
      </div>
      {items.map((item, index) => (
        <div key={index} className="item-row">
          <Downshift
            inputValue={item.inputValue}
            onInputValueChange={(value) => {
              const newItems = [...items];
              newItems[index].inputValue = value;
              setItems(newItems);
            }}
            onChange={(selectedItem) => handleItemChange(selectedItem, index)}
            itemToString={(item) => (item ? item.name : '')}
          >
            {({ getInputProps, getItemProps, getMenuProps, isOpen, highlightedIndex, selectedItem }) => (
              <div className="dropdown">
                <input {...getInputProps()} placeholder="Type to search" />
                <ul {...getMenuProps()}>
                  {isOpen
                    ? getFilteredItems(item.inputValue).map((item, itemIndex) => (
                        <li
                          {...getItemProps({
                            key: item.name,
                            index: itemIndex,
                            item,
                            style: {
                              backgroundColor: highlightedIndex === itemIndex ? 'lightgray' : 'white',
                              fontWeight: selectedItem === item ? 'bold' : 'normal',
                            },
                          })}
                        >
                          {item.name}
                        </li>
                      ))
                    : null}
                </ul>
              </div>
            )}
          </Downshift>
          <input
            type="number"
            min="0"
            value={item.name && item.name != '' ? item.quantity : ''}
            onChange={(e) => handleQuantityChange(e.target.value, index)}
            className="quantity-input"
          />
          <div className="cubic-feet">{item.cubicFeet}</div>
          <div className="total-cubic-feet">{item.totalCubicFeet}</div>
        </div>
      ))}
      <div className="button-group">
        <button onClick={() => setItems([...items, { ...emptyItem }])} className="add-item-btn">
          Add Item
        </button>
      </div>
      <div className="total-cubic-feet-box">Current Total Cubic Feet: {totalCubicFeet}</div>
    </div>
  );
};

export default InventoryList;
