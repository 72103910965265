import React from 'react';
import Banner from './Banner'; // Adjust the path if necessary
import CombinedForm from './CombinedForm'; // Import the CombinedForm component

const App = () => {
  return (
    <div>
      <CombinedForm />
      {/* Other components will go here */}
    </div>
  );
};

export default App;
